/*Этот главный файл сборки для редактора*/
import { } from './action/undo';
import { } from './action/redo';
import { } from './action/enter';
import { } from './action/ul';
import { } from './action/foto';
import { } from './action/video';
import { } from './action/deFrmt';
import { } from './action/frmt';
import { } from './action/selFrmt';
import { } from './action/link';
import { } from './action/addClass';
import { } from './action/delClass';
import { } from './action/delBlck';

import { act } from './act';
import { Jedit } from './Jedit';

var jed = new Jedit(ed);
var edMn = document.querySelector('.edMn');
edMn.addEventListener("click", (e) => {
	let a = e.target.getAttribute('f');
	console.log("a=", a);
	act[a](ed);
}, false);

document.execCommand('defaultParagraphSeparator', false, 'p');
var PHP = {};
PHP.debug = 1;
var JED = {};
JED.usrPermit = 200;



var addr = new Map(); //https://learn.javascript.ru/map-set
//var gS = window.getSelection();

/*============================================================== 
  Обработка кнопок которые должны вызывать сохранение для undo 
================================================================*/
//var { anchorNode, anchorOffset } = gS;//Получаем элемент в котором курсор и позицию курсора в нём

import { Jedit } from './Jedit';

export class UndoBuffer { //Кольцевой буфер для undo/redo
	//3,7,15,31,63... определяет количество ячеек для запоминания
	ptrMask = 63; //Используем логическую маску для вычисления адреса в кольцевом буфере, так проще
	undoCount = 0;
	constructor() {
		this.el = []; //Массив для хранения элементов
		this.wrPtr = 0; //указатель записи
		this.rdPtr = 0; //указатель чтения
	}
	put = (el) => { //Записывает ячейку масива и сдигает указатель записи на следующую ячейку
		Jedit.blMnHide.append(Jedit.blMn);//Удалим меню
		let j = el.getAttribute("j");
		if (!j) {
			j = Jedit.uniq();
			el.setAttribute("j", j);
		}
		let ob = {
			nd: j,
			val: el.innerHTML,
			debug: el.tagName
		}
		this.el[this.ptrMask & this.wrPtr++] = ob;
		this.rdPtr = this.wrPtr;
		//debugWin.insertAdjacentHTML('beforeend', '<p>'+ el.nd + ' / ' + el.debug + '</p>');
		//debugWin.insertAdjacentHTML('beforeend', el.val);
	}
	getRedo = () => { //Выдаёт текущую запись и сдигает указатель чтения на следующую ячейку
		if (this.undoCount > 0) {
			--this.undoCount;
			//То что в буфере записываем в DOM а то что в DOM записываем в буфер
			let chEl = this.el[this.rdPtr & this.ptrMask];
			let j = this.el[this.rdPtr & this.ptrMask].nd;
			let elInner = document.querySelector('[j="' + j + '"]').innerHTML;
			this.el[this.rdPtr & this.ptrMask] = {
				nd: j,
				val: elInner
			};
			this.rdPtr++;
			return chEl; //this.el[this.rdPtr++ & this.ptrMask];
		} else return false;
	}
	getUndo = () => {
		if ((--this.rdPtr & this.ptrMask) in this.el && this.rdPtr >= (this.wrPtr - this.ptrMask - 1)) {
			//console.log('this.rdPtr: ',this.rdPtr, ' / ', this.el[this.rdPtr & this.ptrMask]);
			//Поменяем местами активный элемент (ещё не сохранённый) с элементом в буфере
			let chEl = this.el[this.rdPtr & this.ptrMask];
			let j = this.el[this.rdPtr & this.ptrMask].nd;
			Jedit.blMnHide.append(Jedit.blMn);//Удалим меню
			let elInner = document.querySelector('[j="' + j + '"]').innerHTML;
			//this.el.splice((this.rdPtr+1) & this.ptrMask, 0, { nd: j, val: elInner });
			this.el[this.rdPtr & this.ptrMask] = {
				nd: j,
				val: elInner
			};
			this.undoCount++;
			return chEl;
		} else {
			this.rdPtr++; //Вернём актуальный указатель
			return false;
		}
	}
}
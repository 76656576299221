//форматирование выделенной области внутри элемента
import { act } from '../act';
import { Jedit } from '../Jedit';

act.addClass = (obj) => {//
	console.log('act.addClass obj: ', obj);
	Jedit.ed.actRtEl.classList.add(obj.tag);
	if(obj.tag === 'slogan'){
		let p = document.createElement('p');
		p.innerHTML = '<br>';
		p.classList.add('slogan');
		Jedit.ed.actRtEl.after(p);
		Jedit.cursorToEnd(p);
	}
}

//форматирование выделенной области внутри элемента
import { act } from '../act';
import { Jedit } from '../Jedit';

act.link = (obj) => {//
	//console.log('act.enter param: '); //ожидаем 
	
	console.log('link obj: ', obj);
	document.execCommand('createlink', false, 'https://jed.ru');
}

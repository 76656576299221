import { act } from '../act';
import { Jedit } from '../Jedit';

act.foto = (el) => {
	//let div = document.createElement('div');
	//el.innerHTML = txt;
	//Jedit.ed.actRtEl.replaceWith(el);

	console.log('Jedit.ed.actRtEl: ', Jedit.ed.actRtEl);
	
	Jedit.ed.actRtEl.insertAdjacentHTML('beforebegin', '<div class="faStart" contenteditable=false><img src="cat2.jpg"></div>'); //
	if(Jedit.ed.actRtEl.nextElementSibling) {
		Jedit.ed.actRtEl.remove();
	}
	//el.innerHTML = '<br>';
	//el.remove();
}
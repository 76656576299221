//форматирование выделенной области внутри элемента
import { act } from '../act';
import { Jedit } from '../Jedit';

act.delClass = (obj) => {//
	console.log('act.endFlow obj: ', obj);
	Jedit.ed.actRtEl.classList.remove(obj.tag);
	if(obj.tag === 'slogan'){
		let el = Jedit.ed.actRtEl.nextElementSibling;
		if(el && el.classList.contains('slogan')) el.classList.remove('slogan');
	}
}

import { act } from '../act';
import { Jedit } from '../Jedit';

//Запускается после keyup (когда изменения уже произошли) если записано в стек команд undo
act.enter = () => {//div contenteditable блок
	//console.log('act.enter param: '); //ожидаем 
	var s = document.getSelection();
	var rng = s.getRangeAt(0);
	//if (rng.collapsed) {};
	let {
		startContainer: sC,
		startOffset: sO,
		endContainer: eC,
		endOffset: eO
	} = rng;

	console.log('enter: ', sC);
	
	let j = Jedit.uniq();
	let rootEl = Jedit.getRtEl(sC);
	//let el = rA.nextElementSibling;
	rootEl.setAttribute("j", j);
	//if (!el) return; //Для случая когда курсор в последнем LI и нажимают Enter для завершения функции маркировки/нумерации.
	//console.log('enter el: ', el);
}
export var act = {

}

class UniqNum {
	constructor(start = 0) {
		this.val = start;
	}
	get = () => { //
		return this.val++;
	}
}
export var uniq = new UniqNum();
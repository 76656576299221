//Заменяет корневой контейнер, например p на H1
import { act } from '../act';
import { Jedit } from '../Jedit';

//На входе f элемент массива соответствующий выбранному пункту блок меню
act.frmt = (f) => {
	console.log('act.frmt f: ', f);
	let tag = f.tag;
	let txt = Jedit.ed.actRtEl.innerHTML;
	let el = document.createElement(tag);
	el.innerHTML = txt;
	Jedit.ed.actRtEl.replaceWith(el);
}
import { act } from '../act';

act.undo = (div) => {//div contenteditable блок
	console.log('act.undo is run');
	let state = div.buf.getUndo();
	if (state) {
		let j = document.querySelector('[j="' + state.nd + '"]');
		j.innerHTML = state.val;
	}
}

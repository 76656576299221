//Удаляет форматирование внутри элемента P
import { act } from '../act';
import { Jedit } from '../Jedit';

//
act.deFrmt = (el) => {//
	//console.log('act.enter param: '); //ожидаем 
	console.log('Jedit.ed.lastEl: ', Jedit.ed.lastEl);
	//document.execCommand("removeFormat", false, "");
	//let rEl = Jedit.getRtEl(Jedit.ed.lastEl);
	let txt = Jedit.ed.actRtEl.innerText;
	let p = document.createElement('p');
	p.innerText = txt;
	Jedit.ed.actRtEl.replaceWith(p);
	//Jedit.ed.actRtEl.after(p);
}
import { UndoBuffer } from './UndoBuffer';
import { act } from './act';

export class Jedit { //Редактируем contenteditable блоки
	static inlineTag = ['B', 'I', 'U', 'SUB', 'SUP', 'S', 'VAR', 'SPAN'];
	static tagOffset = ['P', 'LI', 'DIV', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']; //Вспомогательный массив, для вычисления обтекания	
	static blMn = document.createElement('blMn'); //Контейнер для динамического меню блоков и иконки
	static blMnHide = document.createElement('blMnHide'); //Контейнер для прятания меню
	static boxMn = null;//Контейнер для пунктов блок меню
	static bMnArr = [];//Массив содержимого блок меню
	static ed = null;//Активный contenteditable блок, их может быть несколько

	constructor(ed) {
		act.ed = ed;
		Jedit.blMn.setAttribute('contenteditable', false);
		Jedit.blMn.innerHTML = '<div></div><button title="Блочное меню"><svg><use xlink:href="#gear"></use></svg></button>';
		Jedit.boxMn = Jedit.blMn.querySelector('div'); //Контейнер для пунктов меню

		//let padding = window.getComputedStyle(ed).paddingLeft.slice(0, -2) * 1; //Паддинг внутри contenteditable элемента
		//ed.cOffset = padding + ed.coord.left; //Смещение контента от левой границы окна браузера

		this.uniqNum = 0;
		Jedit.uniq = () => this.uniqNum++;

		ed.actEl = null; //Активный элемент, элемент в фокусе
		ed.actRtEl = null; //Корневой элемент (child у contenteditable) внутри которого находится курсор actRtEl
		ed.lastAct = null; // Предыдущий активный корневой элемент
		ed.uLastEl = null; // Предыдущий активный элемент для undo
		ed.lastEl = null;// Предыдущий активный элемент, мб не корневой
		//ed.lastNd = null;// Предыдущий активный узел, мб текстовый
		ed.lastSel = null; //Последняя позиция курсора/последнее выделение
		ed.buf = new UndoBuffer();
		ed.defer = [];
		ed.addEventListener('keyup', function (e) { //http://stackoverflow.com/questions/10862345/contenteditable-areasforce-paragraphs-for-new-lines-cross-browsers
			let t = ed.defer.pop();
			//console.log('keyup t: ', t);
			if (t) act[t.a](t.el);
			//debugger;
		});
		ed.addEventListener("beforeinput", Jedit.onBefore);
		ed.addEventListener('click', Jedit.onClick);
		ed.addEventListener("paste", Jedit.onPaste, false);
		document.addEventListener('selectionchange', Jedit.onSelCh, { passive: false }); //
		ed.addEventListener('blur', Jedit.onBlur);
	}

	static getRtEl(n) {
		var last;
		last = n;
		if ((n = n.parentElement).getAttribute('j') === 'jed') return last; //hasAttribute
		while (n && n.getAttribute('j') !== 'jed') { // 
			last = n;
			n = n.parentElement;
		}
		return last;
	}
	static cursorToEnd(el) {
		let range = document.createRange(); //Create a range (a range is a like the selection but invisible)
		range.selectNodeContents(el); //Включить в диапазон содержимое элемента el
		range.collapse(false); //Свернуть в конце, поставить указатель диапазона (курсор) в конец элеманта
		let s = window.getSelection(); //Получить веделение
		s.removeAllRanges(); //удалим раннее созданный диапазон (курсор) из selection
		s.addRange(range); //добавляем курсор
	}

	static onBefore(e) {
		//console.log('onBefore this: ', this); //В this ожидаем contenteditable блок
		const {
			inputType: a,
			data: chr
		} = e;
		let r = e.getTargetRanges()[0];
		let bR = r.startContainer.nodeType === 1 ? r.startContainer : r.startContainer.parentElement;
		let eR = r.endContainer.nodeType === 1 ? r.endContainer : r.endContainer.parentElement;
		console.log(a, ': ', bR, '/', eR);

		if (bR != eR) {
			//Поищем внутри диапазона блок .faStart, если найдём запретим удаление
			let beg = Jedit.getRtEl(bR);
			let end = Jedit.getRtEl(eR);
			let next = beg;
			while (next != end) {
				next = next.nextElementSibling;
				console.log('next: ', next);
				if (next.classList.contains('faStart')) event.preventDefault();
			}
			console.log('Поиск блоков .faSrart внутри выделенного диапазона: ', bR.nextElementSibling);
			//console.log('sC / eC: ', sC, ' / ', eC);
			//debugger;
			//if (s.containsNode(div, true)) {//Если фокус внутри блока
		}

		if (a.match(/^his/)) { //.match(/^format/)
			e.preventDefault();
			return;
		} else if (a.match(/^del/)) { //.match(/^format/)
			console.log('bR.nextElementSibling: ', bR.nextElementSibling);
			console.log('eR.previousElementSibling: ', eR.previousElementSibling);
		} else if (a.match(/^format/)) {
			console.log('format');
			return;
		} else if (a === 'insertParagraph') {
			//console.log('a = insertParagraph');
			ed.defer.push({
				a: 'enter',
				el: bR
			});
			ed.buf.put(ed);
		} else if (a === 'insertFromPaste') {
			ed.buf.put(ed);
		} else {
			if (chr != ' ' && ed.uLastEl == bR) return; //Запоминаем только после нового введённого слова
			ed.uLastEl = bR;
			let rCntnr = Jedit.getRtEl(bR);
			ed.buf.put(rCntnr);
		}

	}

	static onClick(e) { //
		let gS = document.getSelection();
		let range = gS.getRangeAt(0);
		//console.log('onClick.this:', this); //Ожидаем в this div contenteditable блок
		//const boxMn = Jedit.boxMn;
		if (Jedit.boxMn.innerHTML != '') Jedit.boxMn.innerHTML = ''; //Очищаем меню блока
		//var DocRoot = document.documentElement;
		//DocRoot.style.setProperty('--edMnInLineVw', 'none');
		let el;
		el = e.target;
		//console.log('el.target', el);
		if (el == ed) return;
		let f = el.getAttribute('f');
		if (f) {
			Jedit.blMnHide.append(Jedit.blMn);//Удалим меню

			//Восстановим позицию курсора
			gS.removeAllRanges();
			gS.addRange(ed.lastSel);

			//console.log('Jedit.ed.actRtEl', Jedit.ed.actRtEl);
			ed.buf.put(Jedit.ed);

			console.log('el.f', f);
			act[Jedit.bMnArr[f].act](Jedit.bMnArr[f]);
			return;
		}

		//getRtEl(el);
		if (el.tagName === 'BUTTON') {
			e.preventDefault();
			e.stopPropagation();
			// if (ed.lastEl.tagName === 'P') {
			// 	let prntEl = el.parentElement.parentElement;
			// 	let tag = prntEl.tagName;
			// 	console.log('Меню для', el.parentElement.parentElement.tagName, ed.lastEl);
			// }
			ed.focus();

			//var range = gS.getRangeAt(0);

			let n = range.endContainer;
			let nEl = n.nodeType === 1 ? n : n.parentElement;
			//Получаем дочерний контейнера редактора элемент в котором курсор
			//let last = nEl;
			while (Jedit.tagOffset.indexOf(nEl.tagName) < 0) { // 
				nEl = nEl.parentElement;
			}

			//С помощью range вычисляем смещение текста, для определения есть ли обтекание фото.
			//Выделяем firstChild (в пустом P это <br>), чтобы потом получить правильные координаты когда фото слева (при обтекании фото)
			let fCh = nEl.firstChild;
			range.selectNode(fCh);
			let {left:leftOffset,top:topOffset} = range.getBoundingClientRect();
			let {left,right,bottom:edBottom} = Jedit.ed.getBoundingClientRect();
			let halfWidth = (right - left) / 2;
			console.log('Сдвиг контента:', leftOffset - left, '/', halfWidth);
			//Теперь можем вычислить есть ли обтекание (есть ли фото слева)
			let isFlowAround = 0;
			if ((leftOffset - left) > halfWidth) isFlowAround = 1;

			//Восстанавливаем позицию курсора
			range.collapse(false); //
			gS.removeAllRanges();
			gS.addRange(ed.lastSel);

			Jedit.bMnArr = [];
			if (gS.isCollapsed) {//Если курсор
				//В зависимости от тэга и класса создаём массив Jedit.bMnArr блок меню действий, прямо в нём можно указывать доп. параметры для функции.
				//console.log('ed.lastEl', ed.lastEl, fCh.tagName, fCh.nextElementSibling.tagName);
				let tn = ed.lastEl.tagName;
				//let class = ed.lastEl.className;
				for (let name of ed.lastEl.classList) Jedit.bMnArr.push({ act: 'delClass', name: 'Удалить стиль ' + name, tag: name });

				if (isFlowAround) Jedit.bMnArr.push({ act: 'addClass', name: 'Завершить обтекание', tag: 'clear' });
				// /^H\d+$/.test(tn) проверка на заголовки H1,H2...
				if (/^H\d+$/.test(tn) || tn === 'B' || tn === 'I' || tn === 'S' || tn === 'U'){
					Jedit.bMnArr.push({ act: 'deFrmt', name: 'Деформат' });
					if(!ed.lastEl.classList.contains('slogan') && tn === 'H1') Jedit.bMnArr.push({ act: 'addClass', name: 'Добавить слоган', tag: 'slogan' });
				}
				else if (tn == 'P') {
					Jedit.bMnArr.push({ act: 'delBlck', name: 'Удалить блок' });
					if(!ed.actRtEl.previousElementSibling) Jedit.bMnArr.push({ act: 'frmt', name: 'H1', tag: 'H1' });
					else{
						Jedit.bMnArr.push({ act: 'frmt', name: 'H2', tag: 'H2' });
						Jedit.bMnArr.push({ act: 'frmt', name: 'H3', tag: 'H3' });
						Jedit.bMnArr.push({ act: 'ul', name: 'UL' });
						if(!ed.lastEl.classList.contains('accent')) Jedit.bMnArr.push({ act: 'addClass', name: 'Акцент', tag: 'accent' });
					}
					if (!isFlowAround){//Если слева фото
						//Если курсок в пустом элементе P и нет обтекания
						if (!isFlowAround && fCh.tagName === 'BR' && fCh.nextElementSibling.tagName === 'BLMN'){
							Jedit.bMnArr.push({ act: 'foto', name: 'Вставить фото' });
							Jedit.bMnArr.push({ act: 'video', name: 'Вставить видео' });
						}
					}
					//Jedit.bMnArr.push({ act: 'del', name: 'Удалить блок' });
				}
				else if (tn == 'LI') Jedit.bMnArr.push({ act: 'ul', name: 'Деформат LI' });
				else if (tn == 'A') Jedit.bMnArr.push({ act: 'unlink', name: 'Убрать ссылку' });

			} else {//Если выделена область
				console.log('range', range);
				Jedit.bMnArr.push({ act: 'selFrmt', name: 'Подчеркнутый', tag: 'underline', range: range });
				Jedit.bMnArr.push({ act: 'selFrmt', name: 'Наклонный', tag: 'italic', range: range });
				Jedit.bMnArr.push({ act: 'selFrmt', name: 'Жирный', tag: 'bold', range: range });
				Jedit.bMnArr.push({ act: 'link', name: 'Ссылка', tag: 'underline', range: range });
			}

			let t = '';
			Jedit.bMnArr.forEach((i, k) => {
				t += '<div f="' + k + '">' + i.name + '</div>';
			});
			console.log("boxMn.offsetHeight =", Jedit.bMnArr.length ); //Jedit.boxMn.offsetHeight
			if(topOffset > (edBottom/2)){
				//Jedit.boxMn.style.top = '';
				Jedit.boxMn.style.removeProperty('top');
				Jedit.boxMn.style.bottom = '0';
			}else{
				Jedit.boxMn.style.removeProperty('bottom');
				Jedit.boxMn.style.top = '0';
			}
			Jedit.boxMn.innerHTML = t;
			//Jedit.boxMn.innerHTML = '<div f="deFrmt">1 VDSDVS</div><div f="deFrmt">2 VDSDVS</div><div f="deFrmt">3 VDSDVS</div>';
		}
	}


	static onPaste() {
		let clData = event.clipboardData || window.clipboardData;
		let pasteHtml = clData.getData('text/html');
		//console.log("paste =", pasteHtml);
	}

	//Срабатывает при смене курсора на любой элемент страницы
	static onSelCh() { //https://developer.mozilla.org/en-US/docs/Web/API/Document/selectionchange_event
		let gS = document.getSelection();
		let range = gS.getRangeAt(0);
		// lastSel = gS.getRangeAt(0);
		let n = gS.focusNode;
		let el = n.nodeType === 1 ? n : n.parentElement;
		let t = el;

		//Берём адрес активного contenteditable блока
		while (t && t.getAttribute('j') !== 'jed') t = t.parentElement;
		Jedit.ed = t;
		let ed = t;

		if (el.getAttribute('f') || el == ed) return; //Кнопки меню не должны переназначать активные элементы

		ed.actEl = n;
		ed.lastEl = el;

		//console.log('onSelCh el: ', el);

		ed.actRtEl = Jedit.getRtEl(ed.actEl);
		if (ed.actRtEl !== ed.lastAct) {//Если сменился активный блочный элемент
			//Меняем фон у активного элемента
			if (ed.lastAct) ed.lastAct.removeAttribute("style");
			if (ed.actRtEl) ed.actRtEl.style.backgroundColor = '#eee';

			Jedit.boxMn.innerHTML = ''; //Очищаем меню блока
			ed.actRtEl.append(Jedit.blMn);//Добавляем меню шестерёнку (блочное меню)
		}
		ed.lastAct = ed.actRtEl;


	}

	static onBlur() {
		//console.log('jedit blur: ');
		let gS = document.getSelection();
		//Сохраняем позицию курсора
		//https://stackoverflow.com/questions/13949059/persisting-the-changes-of-range-objects-after-selection-in-html
		ed.lastSel = gS.getRangeAt(0).cloneRange();
		//debugger;
	}

}
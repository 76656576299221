import { act } from '../act';
import { Jedit } from '../Jedit';

act.ul = () => {
	let rootEl,el;
	Jedit.ed.buf.put(ed); //Сохраняем для undo
	let gS = document.getSelection();
	//Если нет выделения, только курсор
	if (gS.isCollapsed) {
		var {
			endContainer: eC,
			endOffset: eO
		} = document.getSelection().getRangeAt(0);
		//console.log('eC.nodeType = ', eC.nodeType);
		//if (eC.nodeType === 3) el = eC.parentElement;
		//else el = eC;
		el = eC.nodeType === 1 ? eC : eC.parentElement;
		console.log('el.tagName = ', el.tagName);
		//console.log('inlineTag.indexOf(el.tagName) = ',el.tagName,'/', inlineTag.indexOf(el.tagName));
		while (Jedit.inlineTag.indexOf(el.tagName) >= 0) el = el.parentElement;
		rootEl = Jedit.getRtEl(el);
		console.log('rootEl ', rootEl.tagName, '/', el.tagName);
		if (rootEl.tagName === 'P') {
			let ul, li;
			if (rootEl.nextElementSibling.tagName === 'UL') {
				ul = rootEl.nextElementSibling;
				li = document.createElement('li');
				ul.prepend(li);
			} else if (rootEl.previousElementSibling.tagName === 'UL') {
				ul = rootEl.previousElementSibling;
				li = document.createElement('li');
				ul.append(li);
			} else {
				ul = document.createElement('ul');
				li = document.createElement('li');
				ul.append(li);
				rootEl.before(ul);
			}
			li.innerHTML = rootEl.innerHTML;
			rootEl.remove();
		} else if (el.tagName === 'LI' && !el.nextElementSibling) { //Если последний LI
			let p = document.createElement('p');
			p.innerHTML = el.innerHTML;
			el.parentElement.after(p);
			el.remove();
			Jedit.cursorToEnd(p);
		} else if (el.tagName === 'LI' && !el.previousElementSibling) { //Если последний LI
			let p = document.createElement('p');
			p.innerHTML = el.innerHTML;
			el.parentElement.before(p);
			el.remove();
			Jedit.cursorToEnd(p);
		}
		ed.focus();
		console.log(eC, eO);
	} else { //Если есть выделение
		var {
			startContainer: sC,
			startOffset: sO,
			endContainer: eC,
			endOffset: eO
		} = gS.getRangeAt(0);
		console.log(sC, sO, eC, eO);
		var begin = Jedit.getRtEl(sC);
		var end = Jedit.getRtEl(eC);

		if (begin.nodeName === 'UL') {
			//document.execCommand('insertUnorderedList', false, 'p');
			console.log('begin === end', begin === end);
			var seleRepl = '';
			var next = begin.firstChild;
			while (next !== end.lastChild) {
				if (next.nodeType === 1) seleRepl += '<p>' + next.innerHTML + '</p>';
				next = next.nextSibling;
			}
			if (next.nodeType === 1) seleRepl += '<p>' + next.innerHTML + '</p>';
			console.log('seleRepl=', seleRepl);

			var prevEl = begin.previousSibling;
			while (prevEl.nodeType !== 1) {
				prevEl = prevEl.previousSibling;
			}
			console.log('prevEl=', prevEl);
			//Расширяем выделенный диапазон для работы document.execCommand. Используем её для работы undo/redo. 
			document.getSelection().setBaseAndExtent(prevEl, prevEl.childNodes.length, end.nextSibling, 0);
			document.execCommand("insertHTML", false, '<p><br></p>');
			document.execCommand("insertHTML", false, seleRepl);
			return;
		}

		var ul = '<ul>';
		var next = begin;
		while (next !== end) {
			if (next.nodeType === 1) {
				ul += '<li>' + next.innerHTML + '</li>';
				console.log('next=', next.innerHTML);
			}
			next = next.nextSibling;
		}
		ul += '<li>' + next.innerHTML + '</li></ul>';
		// выделить всё содержимое от нулевого потомка тега <p> до последнего
		document.getSelection().setBaseAndExtent(begin, 0, end, end.childNodes.length);
		//var seleNodes = document.getSelection().getRangeAt(0).cloneContents();
		document.execCommand("insertHTML", false, ul);
		//JED.changed = 1;
	}
}